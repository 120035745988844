import { Skeleton } from 'primereact/skeleton'

import type { ProjectsStats } from '@typings/Survey'

type Props = {
  projectId: string
  projectsStats: ProjectsStats
  isLoading: boolean
}

export default function TargetQuantityColumn({ isLoading, projectId, projectsStats }: Props) {
  const projectStats = projectsStats?.[projectId]

  if (isLoading) {
    return <Skeleton height='0.75rem' width='5rem' />
  }

  if (!projectStats?.targetN) {
    return <span>0</span>
  }

  return <span>{projectStats?.targetN}</span>
}
