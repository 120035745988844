import { REQUIRED_FIELDS_TABLE_MAP } from '@components/DataTable/data/data'

import type { UserColumnChoice, UserColumnSettingsTableType } from '@typings/Users'

export function handleMissingField(
  columnChoices: Array<UserColumnChoice>,
  tableType: UserColumnSettingsTableType,
) {
  const requiredField = REQUIRED_FIELDS_TABLE_MAP[tableType]

  return columnChoices.includes(requiredField) ? columnChoices : [requiredField, ...columnChoices]
}
