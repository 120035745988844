import { DragDropContext, Draggable, Droppable, type OnDragEndResponder } from 'react-beautiful-dnd'

import ColumnSettingsSection from '@components/DataTable/ColumnSettingsSection'
import ColumnSettingsSectionItem from '@components/DataTable/ColumnSettingsSectionItem'

import type { UserColumnChoice, UserColumnSettingsTableType } from '@typings/Users'
import type { ColumnProps } from 'primereact/column'

type Props = {
  columnsStructure: Record<UserColumnChoice, ColumnProps & { isHidden?: boolean }>
  visibleColumns: Array<UserColumnChoice>
  tableType: UserColumnSettingsTableType
  onDragEnd: OnDragEndResponder
  invisibleColumns: Array<UserColumnChoice>
  onColumnToggle: (column: UserColumnChoice) => void
}

export default function ColumnSettings({
  columnsStructure,
  invisibleColumns,
  onColumnToggle,
  onDragEnd,
  tableType,
  visibleColumns,
}: Props) {
  return (
    <div className='-m-5 grid h-96 w-80 gap-3 overflow-y-auto rounded-md bg-white p-2'>
      <ColumnSettingsSection title='Shown in table'>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable direction='vertical' droppableId='droppable'>
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {visibleColumns?.map((item, index) => {
                  const { header: label } = columnsStructure[item] || {}

                  return (
                    <Draggable key={item} draggableId={item} index={index}>
                      {({ draggableProps, dragHandleProps, innerRef }) => (
                        <ColumnSettingsSectionItem
                          {...draggableProps}
                          {...dragHandleProps}
                          innerRef={innerRef}
                          item={item}
                          label={label}
                          tableType={tableType}
                          onColumnToggle={onColumnToggle}
                        />
                      )}
                    </Draggable>
                  )
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </ColumnSettingsSection>

      <ColumnSettingsSection title='Hidden in table'>
        {invisibleColumns?.map((item) => {
          const { header: label } = columnsStructure[item] || {}

          return (
            <ColumnSettingsSectionItem
              key={item}
              item={item}
              label={label}
              tableType={tableType}
              onColumnToggle={onColumnToggle}
            />
          )
        })}
      </ColumnSettingsSection>
    </div>
  )
}
