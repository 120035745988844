import sanitizeHtml from 'sanitize-html'

const GENERAL_SANITIZATION_CONFIG = {
  allowedTags: [],
  allowedAttributes: {},
}

export const SKIP_IMAGE_SANITIZATION_CONFIG = {
  allowedAttributes: { img: ['src', 'alt'] },
  allowedTags: ['img'],
}

export function getSanitizedContent(content?: string, config = GENERAL_SANITIZATION_CONFIG) {
  if (!content) return null

  return sanitizeHtml(content, config)?.replaceAll('&amp;', '&')?.replaceAll('&nbsp;', ' ')
}
