import { WORKFLOW_STATE_TYPES } from '@constants'
import { getSanitizedContent } from '@helpers/get-sanitized-content'
import { Label, LabelProps } from '@ui-kit/Label'

import type { WorkflowState, WorkflowStateType } from '@typings/Workflows'

type WorkflowStateTypes = {
  [key in WorkflowStateType]: {
    type: LabelProps['type']
    label: string
    icon?: string
  }
}

const legacyDecisionsLabelMap = {
  ON_HOLD: 'On hold',
  MANUALLY_APPROVED: 'Approved',
  MANUALLY_REJECTED: 'Rejected',
  DISQUALIFIED: 'Disqualified',
  REQUIRE_MANUAL_INPUT: 'Ready for review',
  PENDING_CLIENT_APPROVAL: 'Pending client approval',
  PENDING_INTERVIEW: 'Pending interview',
  REDIRECTED_TO_EXTERNAL: 'Waiting for client',
}

export const workflowStateTypes = {
  [WORKFLOW_STATE_TYPES.ON_HOLD]: {
    type: 'info',
    label: 'On hold',
  },
  [WORKFLOW_STATE_TYPES.NEW]: {
    type: '',
    label: 'New',
  },
  [WORKFLOW_STATE_TYPES.IN_PROGRESS]: {
    type: 'warning',
    label: 'In progress',
  },
  [WORKFLOW_STATE_TYPES.ACCEPTED]: {
    type: 'success',
    label: 'Accepted',
  },
  [WORKFLOW_STATE_TYPES.NOT_ACCEPTED]: {
    type: 'danger',
    label: 'Not accepted',
  },
} as WorkflowStateTypes

type Props = {
  icon?: React.ReactNode
  workflowStateType: WorkflowStateType
  workflowStateName?: WorkflowState['name']
  className?: string
}

export function tryFormatLegacyWorkflowStateName(workflowStateName: string) {
  return getSanitizedContent(legacyDecisionsLabelMap[workflowStateName] ?? workflowStateName)
}

export default function WorkflowStateTypeLabel({
  className,
  icon,
  workflowStateName,
  workflowStateType,
}: Props): JSX.Element | null {
  const { label, ...props } = workflowStateTypes[workflowStateType] || {}

  if (!label) {
    return null
  }

  return (
    <Label icon={icon} {...props} className={className}>
      {workflowStateName ? tryFormatLegacyWorkflowStateName(workflowStateName) : label}
    </Label>
  )
}
