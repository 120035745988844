import { Column, type ColumnProps } from 'primereact/column'
import { type DataTableProps, DataTable as PrimeDataTable } from 'primereact/datatable'

import DataTableError from '@components/DataTable/DataTableError'
import SkeletonDataTable from '@components/DataTable/SkeletonDataTable'
import EmptyState from '@components/EmptyState'

import type { UserColumnChoice, UserColumnSettingsTableType } from '@typings/Users'

type Props = DataTableProps<any> & {
  columnsStructure: Record<string, ColumnProps & { isHidden?: boolean }>
  error?: Error | string
  customEmptyState?: React.ReactNode
  tableType: UserColumnSettingsTableType
  visibleColumns: Array<UserColumnChoice> | null
  isLoading: boolean
  columnsStart?: React.ReactNode
  columnsEnd?: React.ReactNode
  shouldShowExpander?: boolean
  shouldShowCheckSelect?: boolean
}

export default function DataTableBody({
  children,
  columnsEnd,
  columnsStart,
  columnsStructure,
  customEmptyState,
  error,
  isLoading,
  shouldShowCheckSelect,
  shouldShowExpander,
  tableType,
  value,
  visibleColumns,
  ...props
}: Props) {
  if (error) {
    return <DataTableError error={error} />
  }

  if (value?.length === 0) {
    const emptyState = customEmptyState || (
      <EmptyState text={`No ${tableType?.toLocaleLowerCase()} found.`} />
    )

    return <>{emptyState}</>
  }

  if (isLoading) {
    return <SkeletonDataTable />
  }

  return (
    <PrimeDataTable selectionAutoFocus={false} selectionMode='checkbox' value={value} {...props}>
      {shouldShowExpander && <Column className='w-4' expander={true} />}
      {shouldShowCheckSelect && (
        <Column className='w-4' headerClassName='w-4' selectionMode='multiple' />
      )}

      {columnsStart}

      {visibleColumns?.map((column) => {
        const { isHidden, ...columnData } = columnsStructure[column] || {}

        if (!isHidden && columnData) {
          return <Column key={column} {...columnData} />
        }
      })}

      {columnsEnd}
    </PrimeDataTable>
  )
}
