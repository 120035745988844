import emojiFlags from 'emoji-flags'

import { cx } from '@helpers/cx'

type Props = {
  countryCode: string
  className?: string
}

export default function CountryFlag({ className, countryCode }: Props) {
  return (
    <div className={cx('align-middle text-[1.5rem] leading-0', className)}>
      {emojiFlags.countryCode(countryCode)?.emoji}
    </div>
  )
}
