import { useState } from 'react'

import { type ColumnProps } from 'primereact/column'
import { type DataTableProps } from 'primereact/datatable'

import ColumnSettingsButton from '@components/DataTable/ColumnSettingsButton'
import DataTableBody from '@components/DataTable/DataTableBody'
import DataTableHeader from '@components/DataTable/DataTableHeader'
import type { Breadcrumbs } from '@views/folder/hooks/use-folder-breadcrumbs'

import type { UserColumnChoice, UserColumnSettingsTableType } from '@typings/Users'

type Props = Omit<DataTableProps<any>, 'header'> & {
  columnsEnd?: React.ReactNode | Array<React.ReactNode>
  columnsStart?: React.ReactNode | Array<React.ReactNode>
  columnsStructure: Record<string, ColumnProps & { isHidden?: boolean }>
  tableType: UserColumnSettingsTableType
  error?: Error | string
  customEmptyState?: React.ReactNode
  shouldShowExpander?: boolean
  shouldShowCheckSelect?: boolean
  header: {
    title: string | React.ReactNode
    titleBadge?: React.ReactNode
    actionElements?: React.ReactNode
    filterElements?: React.ReactNode
    isLoading?: boolean
    breadcrumbs?: Breadcrumbs
    breadcrumbsBadge?: React.ReactNode
  }
}

export default function DataTable({
  columnsStructure,
  customEmptyState,
  header,
  tableType,
  title,
  ...props
}: Props) {
  const [visibleColumns, setVisibleColumns] = useState<Array<UserColumnChoice> | null>(null)
  const isLoading = !props.value || !visibleColumns

  return (
    <>
      <DataTableHeader
        {...header}
        columnSettings={
          <ColumnSettingsButton
            columnsStructure={columnsStructure}
            setVisibleColumns={setVisibleColumns}
            tableType={tableType}
            visibleColumns={visibleColumns || []}
          />
        }
      />

      <div className='mt-6'>
        <DataTableBody
          columnsStructure={columnsStructure}
          customEmptyState={customEmptyState}
          isLoading={isLoading}
          tableType={tableType}
          visibleColumns={visibleColumns}
          {...props}
        />
      </div>
    </>
  )
}
