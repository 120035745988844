import { useRef } from 'react'

import { useRouter } from 'next/router'
import { PrimeIcons } from 'primereact/api'
import { Button } from 'primereact/button'
import { Menu } from 'primereact/menu'

import { capitalizeFirstLetter } from '@helpers/change-case'

type Props = {
  surveysFilter: SurveysFilter
}

export type SurveysFilter = 'ACTIVE' | 'ARCHIVED' | 'ALL'

export const SURVEYS_FILTER: Record<SurveysFilter, SurveysFilter> = {
  ALL: 'ALL',
  ACTIVE: 'ACTIVE',
  ARCHIVED: 'ARCHIVED',
}

export const DEFAULT_SURVEY_FILTER = SURVEYS_FILTER.ACTIVE

export default function SurveysHeaderFilter({ surveysFilter }: Props) {
  const router = useRouter()
  const menuEl = useRef<Menu | null>(null)

  const handleMenuClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    menuEl.current.toggle(e)
  }

  const handleFilter = ({ item, originalEvent: e }) => {
    e.preventDefault()
    e.stopPropagation()

    const isDefaultSurveyFilter = item.id === DEFAULT_SURVEY_FILTER
    router.push({
      pathname: router.pathname,
      query: { ...router.query, f: isDefaultSurveyFilter ? null : item.id },
    })
  }

  const model = Object.keys(SURVEYS_FILTER).map((surveyFilter: SurveysFilter) => ({
    command: handleFilter,
    id: surveyFilter,
    label: capitalizeFirstLetter(surveyFilter.toLowerCase()),
  }))

  return (
    <>
      <Menu ref={menuEl} className='w-40' model={model} popup />

      <Button
        aria-haspopup
        className='p-button p-button-secondary p-button-outlined p-button-sm'
        icon={PrimeIcons.ANGLE_DOWN}
        iconPos='right'
        label={`Show: ${capitalizeFirstLetter(SURVEYS_FILTER[surveysFilter]?.toLowerCase())}`}
        onClick={handleMenuClick}
      />
    </>
  )
}
