import type { NextRouter } from 'next/router'

type Args = {
  event: React.MouseEvent
  link: string
  router: NextRouter
}

// open new window if cmd/ctrl key is pressed
export default function openLinkOnCmdClick({ event, link, router }: Args) {
  if (event.metaKey) {
    window.open(link, '_blank')
  } else {
    router.push(link)
  }
}
