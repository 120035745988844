import { Skeleton } from 'primereact/skeleton'
import { Tooltip } from 'primereact/tooltip'

import { getMonogram } from '@helpers/get-monogram'
import { useUsers } from '@hooks/use-pµs-api'

type Props = {
  assigneeEmail: string
  id: string
}

export default function AssigneeColumn({ assigneeEmail, id }: Props) {
  const { isLoadingUsers, users } = useUsers()

  const userName = users?.find((user) => user.email === assigneeEmail)

  if (isLoadingUsers) {
    return (
      <Skeleton className='inline-flex !h-7 !w-7 items-center justify-center overflow-hidden rounded-full bg-branding-light md:h-8 md:w-8' />
    )
  }

  if (!userName) {
    return <span>-</span>
  }

  return (
    <>
      <Tooltip target={`[data-survey-id="${id}"]`} />
      <span
        className='inline-flex h-7 w-7 items-center justify-center overflow-hidden rounded-full bg-branding-light md:h-8 md:w-8'
        data-pr-position='bottom'
        data-pr-tooltip={userName.name}
        data-survey-id={id}
      >
        <span className='text-sm font-semibold text-branding'>{getMonogram(userName?.name)}</span>
      </span>
    </>
  )
}
