import classNames from 'classnames'
import Link from 'next/link'
import { PrimeIcons } from 'primereact/api'
import { Skeleton } from 'primereact/skeleton'

import type { Breadcrumbs } from '@views/folder/hooks/use-folder-breadcrumbs'
import styles from '@views/project/styles/ProjectBreadcrumb.module.css'

type Props = {
  breadcrumbs: Breadcrumbs
  isLoading: boolean
  surveyName?: string
}

function ItemBody({ name }) {
  return (
    <>
      <span className='text-sm text-gray-600'>{name}</span>
      <i className={classNames(PrimeIcons.CHEVRON_RIGHT, 'px-2 text-xs text-gray-400')} />
    </>
  )
}

function Item(props) {
  if (!props.link) {
    return (
      <span className='cursor-default whitespace-nowrap'>
        <ItemBody {...props} />
      </span>
    )
  }

  return (
    <Link className='whitespace-nowrap text-gray-400 hover:underline' href={props.link}>
      <ItemBody {...props} />
    </Link>
  )
}

export default function ProjectBreadcrumb({ breadcrumbs, isLoading, surveyName }: Props) {
  if (isLoading) {
    return <Skeleton className='-mt-px inline-flex align-middle' height='1.25rem' width='15rem' />
  }

  return (
    <div className={styles.breadcrumb}>
      {breadcrumbs?.map(({ link, name }, i: number) => (
        <Item key={i} link={link} name={name} />
      ))}
      {surveyName && <Item link={null} name={surveyName} />}
    </div>
  )
}
