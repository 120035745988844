import { specialisationB2B } from '@views/settings/data/categorisation-data'

import type { Survey } from '@typings/Survey'

type Props = {
  categorisation: Survey['categorisation']
}

const specialisationMap = specialisationB2B.reduce((acc, { emoji, label, value }) => {
  acc[value] = emoji + ' ' + label

  return acc
}, {})

export default function SpecialisationsColumn({ categorisation }: Props) {
  return (
    <div className='flex flex-wrap gap-1'>
      {categorisation?.specialisations?.map((specialisation) => (
        <span key={specialisation} className='whitespace-nowrap'>
          {specialisationMap[specialisation]}
        </span>
      ))}
    </div>
  )
}
