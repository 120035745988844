import SkeletonDataTableHeader from '@components/DataTable/SkeletonDataTableHeader'
import type { Breadcrumbs } from '@views/folder/hooks/use-folder-breadcrumbs'
import ProjectBreadcrumb from '@views/project/project-content/components/ProjectBreadcrumb'

type Props = {
  title: string | React.ReactNode
  titleBadge?: React.ReactNode
  breadcrumbs?: Breadcrumbs
  breadcrumbsBadge?: React.ReactNode
  isArchived?: boolean
  isLoading?: boolean
  actionElements?: React.ReactNode
  filterElements?: React.ReactNode
  columnSettings?: React.ReactNode
}

export default function DataTableHeader({
  actionElements,
  breadcrumbs,
  breadcrumbsBadge,
  columnSettings,
  filterElements,
  isLoading,
  title,
  titleBadge,
}: Props) {
  return (
    <div className='flex flex-col'>
      <div className='mt-8 flex items-end justify-between'>
        {isLoading ? (
          <SkeletonDataTableHeader />
        ) : (
          <div className='flex flex-col gap-2'>
            <div className='-mt-px flex items-center'>
              {breadcrumbs && (
                <>
                  <ProjectBreadcrumb breadcrumbs={breadcrumbs} isLoading={false} />
                  {breadcrumbsBadge}
                </>
              )}
            </div>

            <h2 className='h2 line-clamp-1'>
              {title}
              {titleBadge}
            </h2>
          </div>
        )}

        <div className='flex gap-x-2'>
          {actionElements && actionElements}
          {columnSettings && columnSettings}
        </div>
      </div>

      {filterElements && <div className='mt-4'>{filterElements}</div>}
    </div>
  )
}
