import dayjs from 'dayjs'

import WorkflowStateProgress from '@components/WorkflowStateProgress'
import { DATE_FORMAT, WORKFLOW_STATE_TYPES } from '@constants'
import { usePermissions } from '@hooks/use-permissions'
import { useProjectsStats } from '@hooks/use-pµs-api'
import AssigneeColumn from '@views/surveys/SurveysTableComponents/AssigneeColumn'
import CountriesColumn from '@views/surveys/SurveysTableComponents/CountriesColumn'
import DeadlineColumn from '@views/surveys/SurveysTableComponents/DeadlineColumn'
import SpecialisationsColumn from '@views/surveys/SurveysTableComponents/SpecialisationsColumn'
import TargetQuantityColumn from '@views/surveys/SurveysTableComponents/TargetQuantityColumn'
import SurveyStatusBadge from '@views/surveys/SurveyStatusBadge'

type UseProjectColumnStructure = {
  isFolder?: boolean
}

export function useProjectColumnStructure({ isFolder = false }: UseProjectColumnStructure = {}) {
  const { cannot } = usePermissions()
  const { isLoadingProjectsStats, projectsStats } = useProjectsStats()

  const canNotViewAnalytics = cannot('read', 'Analytics')

  const structure = {
    NAME: {
      body: ({ name }) => <span className='font-semibold'>{name}</span>,
      field: 'name',
      header: 'Project name',
      sortable: true,
    },

    STATUS: {
      body: ({ surveyStatus }) => <SurveyStatusBadge surveyStatus={surveyStatus} />,
      className: 'w-28',
      field: 'surveyStatus',
      header: 'Status',
      sortable: true,
    },

    ASSIGNEE: {
      body: ({ assignee, id }) => <AssigneeColumn assigneeEmail={assignee} id={id} />,
      className: 'w-5 text-center',
      field: 'assignee',
      header: 'Assignee',
      sortable: true,
    },

    FOLDER_NAME: {
      isHidden: isFolder,
      className: 'w-36 whitespace-nowrap',
      field: 'clientName',
      header: 'Folder',
      sortable: true,
    },

    TARGETED_QUANTITY: {
      isHidden: canNotViewAnalytics,
      body: ({ id }) => (
        <TargetQuantityColumn
          isLoading={isLoadingProjectsStats}
          projectId={id}
          projectsStats={projectsStats}
        />
      ),
      className: 'w-28',
      header: 'Target N',
    },

    COLLECTED_RESPONSES: {
      isHidden: canNotViewAnalytics,
      body: ({ id }) => (
        <WorkflowStateProgress
          hiddenStates={[WORKFLOW_STATE_TYPES.NOT_ACCEPTED]}
          isLoading={isLoadingProjectsStats}
          itemId={id}
          projectStats={projectsStats?.[id]}
        />
      ),
      className: 'w-40 text-center',
      header: 'Collected responses',
    },

    DEADLINE: {
      body: ({ deadline, id }) => <DeadlineColumn deadline={deadline} id={id} />,
      className: 'w-32 text-center',
      field: 'deadline',
      header: 'Deadline',
      headerClassName: 'flex justify-end pr-2',
      sortable: true,
    },

    PROJECT_ID: {
      field: 'id',
      header: 'Id',
    },
    EXTERNAL_ID: {
      field: 'externalId',
      header: 'External Id',
    },
    DESCRIPTION: {
      field: 'description',
      header: 'Description',
    },
    START_DATE: {
      field: 'startDate',
      header: 'Start date',
      body: ({ startDate }) => (startDate ? dayjs(startDate).format(DATE_FORMAT) : '–'),
    },
    CURRENCY: {
      field: 'currency',
      header: 'Currency',
    },
    PROJECT_TYPE: {
      field: 'projectType',
      header: 'Project Type',
    },
    COUNTRIES: {
      field: 'countries',
      header: 'Countries',
      body: ({ countries, id }) => <CountriesColumn countries={countries} surveyId={id} />,
    },
    HOSTING: {
      field: 'categorisation.hosting',
      header: 'Hosting',
    },
    TOPIC: {
      field: 'categorisation.topic',
      header: 'Topic',
    },
    TARGET_TYPE: {
      field: 'categorisation.targetType',
      header: 'Target type',
    },
    SPECIALISATIONS: {
      field: 'categorisation.specialisations',
      header: 'Specialisations',
      body: ({ categorisation }) => <SpecialisationsColumn categorisation={categorisation} />,
    },
  }

  return structure
}
