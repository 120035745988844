import { useState } from 'react'

import { Star } from '@phosphor-icons/react'

import { cx } from '@helpers/cx'

type Props = {
  handleStarredSave: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  isStarredProject: boolean
}

export default function StarredColumnIcon({ handleStarredSave, isStarredProject }: Props) {
  const [isHovered, setIsHovered] = useState(false)

  return (
    <button
      className='relative top-px p-1'
      onClick={handleStarredSave}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isStarredProject ? (
        <Star className='fill-yellow-400' size={18} weight='fill' />
      ) : (
        <Star
          className={cx(isHovered ? 'fill-yellow-400' : 'text-gray-400')}
          size={18}
          weight={isHovered ? 'fill' : 'regular'}
        />
      )}
    </button>
  )
}
