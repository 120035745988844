import { Skeleton } from 'primereact/skeleton'

export default function SkeletonDataTableHeader() {
  return (
    <div className='flex flex-col gap-2'>
      <Skeleton height='1.375rem' width='10rem' />

      <h2 className='h2 line-clamp-1'>
        <Skeleton height='2rem' width='16rem' />
      </h2>
    </div>
  )
}
