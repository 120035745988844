export const PROMOTION_TYPES = {
  B2C: 'B2C',
  B2B: 'B2B',
}

export const TOPIC_CONSUMER = 'consumer'

export const topicsList = [
  'communication',
  'finance',
  'logistics',
  'professional services',
  'health',
  'hospitality',
  'public services',
  'agriculture',
  'science',
  'nature',
  'construction',
  'manufacturing',
  'infrastructure',
  'communication',
  'retail',
  'tech',
  'transportation',
]

export const hostsList = [
  { label: 'iqurate-hosted', value: 'qrowdsy' },
  { label: 'Client-hosted', value: 'client' },
]

export const specialisationB2C = [
  { label: 'Beauty', value: 'beauty', emoji: '💄' },
  { label: 'Wellness', value: 'wellness', emoji: '🪷' },
  { label: 'Food & Drink', value: 'food_drink', emoji: '🍔' },
  { label: 'Hospitality', value: 'hospitality', emoji: '🛏️' },
  { label: 'Leisure', value: 'leisure', emoji: '🏖️' },
  { label: 'Sport', value: 'sport', emoji: '⚽' },
  { label: 'Music', value: 'music', emoji: '🎵' },
  { label: 'Technology', value: 'technology', emoji: '🖥️' },
  { label: 'Gambling', value: 'gambling', emoji: '🎲' },
  { label: 'Fashion', value: 'fashion', emoji: '👗' },
]

export const specialisationB2B = [
  ...specialisationB2C,
  { label: 'Finance', value: 'finance', emoji: '💵' },
  { label: 'Law', value: 'law', emoji: '⚖️' },
  { label: 'Operations', value: 'operations', emoji: '📄' },
  { label: 'IT', value: 'it', emoji: '⌨️' },
  { label: 'HR', value: 'hr', emoji: '🗂️' },
  { label: 'Marketing', value: 'marketing', emoji: '🎯' },
  { label: 'Cleaning', value: 'cleaning', emoji: '🧹' },
]
