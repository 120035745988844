import { getStarredProjectStorage } from '@views/surveys/helpers/handle-starred-project-storage'

import type { Surveys } from '@typings/Survey'

export const getStarredProjects = (projects: Surveys) => {
  const starredProjectsIds = getStarredProjectStorage()
  const starredProjects = projects?.filter((project) => starredProjectsIds?.includes(project.id))

  return starredProjects
}
