import type { UserColumnChoice } from '@typings/Users'
import type { ColumnProps } from 'primereact/column'

type ColumnsStructure = Record<string, ColumnProps & { isHidden?: boolean }>

export const makeAvailableColumnsForStructure =
  (columnsStructure: ColumnsStructure) =>
  (columns: Array<UserColumnChoice> = []) =>
    columns?.filter((columnChoice) => {
      const isDefinedInStructure = columnChoice in columnsStructure
      const isHidden = !!columnsStructure[columnChoice]?.isHidden

      return isDefinedInStructure && !isHidden
    }) || []
