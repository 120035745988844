import { Tooltip } from 'primereact/tooltip'

import CountryFlag from '@components/CountryFlag'

import type { Survey } from '@typings/Survey'

type Props = {
  surveyId: string
  countries: Survey['countries']
}

const MAX_VISIBLE_COUNTRIES = 4

export default function CountriesColumn({ countries, surveyId }: Props) {
  if (!countries?.length) {
    return <span>-</span>
  }

  const visibleCountries = countries.slice(0, MAX_VISIBLE_COUNTRIES)
  const countriesCount = countries.length

  return (
    <div>
      <div
        className='flex flex-nowrap items-center gap-1'
        data-countries-sid={surveyId}
        data-pr-position='bottom'
        data-pr-tooltip={countries.join(', ')}
      >
        {visibleCountries?.map((country) => (
          <CountryFlag key={country} className='leading-tight' countryCode={country} />
        ))}

        {countriesCount > MAX_VISIBLE_COUNTRIES && (
          <span className='font-semibold leading-tight'>
            +{countriesCount - MAX_VISIBLE_COUNTRIES}
          </span>
        )}
      </div>

      <Tooltip target={`[data-countries-sid="${surveyId}"]`} />
    </div>
  )
}
