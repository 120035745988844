import classNames from 'classnames'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { Tooltip } from 'primereact/tooltip'

dayjs.extend(relativeTime)

type Props = {
  deadline: number
  id: string
}

export default function DeadlineColumn({ deadline, id }: Props) {
  const surveyEndInDays = dayjs(deadline).diff(dayjs(), 'day')

  if (!deadline) {
    return <span>-</span>
  }

  return (
    <div className='flex justify-end pr-2'>
      <span
        className={classNames('cursor-help', {
          'text-red-600': surveyEndInDays < 0,
        })}
        data-pr-position='bottom'
        data-pr-tooltip={dayjs(deadline).format('MMM D, YYYY')}
        data-survey-id={id}
      >
        {dayjs().to(dayjs(deadline))}
      </span>

      <Tooltip target={`[data-survey-id="${id}"]`} />
    </div>
  )
}
