import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Skeleton } from 'primereact/skeleton'

export default function SkeletonDataTable() {
  return (
    <DataTable value={Array.from({ length: 10 })}>
      <Column body={() => <Skeleton height='0.75rem' width='16rem' />} header='&nbsp;' />
      <Column body={() => <Skeleton height='0.75rem' width='5rem' />} header='&nbsp;' />
      <Column body={() => <Skeleton height='0.75rem' width='5rem' />} header='&nbsp;' />
      <Column body={() => <Skeleton height='0.75rem' width='5rem' />} header='&nbsp;' />
      <Column body={() => <Skeleton height='0.75rem' width='5rem' />} header='&nbsp;' />
    </DataTable>
  )
}
