import { useEffect, useState } from 'react'

import { NextApiRequestQuery } from 'next/dist/server/api-utils'
import { useRouter } from 'next/router'
import { z } from 'zod'

import { NON_ARCHIVED_PROJECT_STATUSES, S_STATUS } from '@constants'
import { useSurveys } from '@hooks/use-survey-provider-service-api'
import { getStarredProjects } from '@views/surveys/helpers/get-starred-projects'
import { PROJECTS_VIEW, ProjectsView } from '@views/surveys/ProjectsPageContainer'
import {
  DEFAULT_SURVEY_FILTER,
  SURVEYS_FILTER,
  type SurveysFilter,
} from '@views/surveys/SurveysHeaderFilter'

import type { SurveysSearchParams } from '@typings/Survey'

function getSurveyStatusesFilter(filter: string) {
  switch (filter) {
    case SURVEYS_FILTER.ARCHIVED:
      return [S_STATUS.ARCHIVED]
    case SURVEYS_FILTER.ACTIVE:
      return NON_ARCHIVED_PROJECT_STATUSES
    default:
      return []
  }
}

function getValidFilterQuery(query: NextApiRequestQuery): SurveysFilter {
  const filter = query.f || ''

  try {
    return z.enum(['ALL', 'ACTIVE', 'ARCHIVED']).parse(filter)
  } catch (error) {
    return DEFAULT_SURVEY_FILTER
  }
}

export const useFilteredSurveys = (
  surveysSearchParams?: SurveysSearchParams,
  view?: ProjectsView,
) => {
  const router = useRouter()
  const filter = getValidFilterQuery(router.query)

  const searchParams: SurveysSearchParams = {
    ...(surveysSearchParams && { ...surveysSearchParams }),
    statuses: getSurveyStatusesFilter(filter),
  }

  const { surveys, surveysError } = useSurveys({ searchParams })

  //filter out the starred projects on the starred view
  const [starredProjects, setStarredProjects] = useState(getStarredProjects(surveys))

  useEffect(() => {
    if (view === PROJECTS_VIEW.STARRED) {
      setStarredProjects(getStarredProjects(surveys))
    }

    const handleStorageChange = () => {
      if (view === PROJECTS_VIEW.STARRED) {
        setStarredProjects(getStarredProjects(surveys))
      }
    }

    window.addEventListener('storage', handleStorageChange)

    return () => {
      window.removeEventListener('storage', handleStorageChange)
    }
  }, [surveys, view])

  return {
    surveys: view === PROJECTS_VIEW.STARRED ? starredProjects : surveys,
    surveysFilter: filter,
    surveysError,
  }
}
