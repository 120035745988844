export function capitalize(string: string): string {
  return string?.toLowerCase()?.replace(/^\w/, (c) => c.toUpperCase())
}

export function pascalCase(string: string): string {
  return string.replace(/\w+/g, (c) => c[0].toUpperCase() + c.slice(1).toLowerCase())
}

export function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function upperSnakeCase(string: string): string {
  return string
    .replace(/\W+/g, ' ')
    .split(/ |\B(?=[A-Z])/)
    .map((word) => word.toUpperCase())
    .join('_')
}
