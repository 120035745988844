import { setStarredProjectStorage } from '@views/surveys/helpers/handle-starred-project-storage'
import StarredColumnIcon from '@views/surveys/SurveysTableComponents/StarredColumnIcon'

type Props = {
  projectId: string
  starredProjects: Array<string>
}

export default function StarredColumn({ projectId, starredProjects }: Props) {
  const isStarredProject = starredProjects.includes(projectId)

  const handleStarredSave = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    e.stopPropagation()

    let newStarred = []

    if (isStarredProject) {
      newStarred = starredProjects.filter((id) => id !== projectId)
    } else {
      newStarred = [...starredProjects, projectId]
    }

    setStarredProjectStorage(newStarred)

    //trigger event to update starred projects
    window.dispatchEvent(new Event('storage'))
  }

  return (
    <StarredColumnIcon handleStarredSave={handleStarredSave} isStarredProject={isStarredProject} />
  )
}
