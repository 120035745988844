import { Bug, Warning } from '@phosphor-icons/react'

import Message from '@ui-kit/Message'

type Props = {
  error: Error | string
}

export default function DataTableError({ error }: Props) {
  const errorMessage = error instanceof Error ? error.message : error

  if (typeof errorMessage !== 'string') {
    return null
  }

  return (
    <div className='flex w-full flex-col items-center justify-center rounded-md border-2 border-dashed border-red-200 bg-red-50/70 p-20 text-center'>
      <div className='flex flex-col items-center space-y-1'>
        <Bug className='text-gray-700' size={56} />
        <p className='h3 text-gray-700'>You have encountered an error</p>
        <p className='text-sm text-gray-600'>If the problem persists, please contact support.</p>
      </div>

      <Message className='mt-6' icon={<Warning />} type='danger'>
        {errorMessage}
      </Message>
    </div>
  )
}
