import { useRouter } from 'next/router'
import { PrimeIcons } from 'primereact/api'
import { Button } from 'primereact/button'
import qs from 'query-string'

import { usePermissions } from '@hooks/use-permissions'
import { useRootFolder } from '@views/folder/hooks/use-root-folder'
import FolderHeaderFilter, { type SurveysFilter } from '@views/surveys/SurveysHeaderFilter'

type Props = {
  surveysFilter: SurveysFilter
  hideActions?: boolean
}

export default function SurveysActions({ hideActions, surveysFilter }: Props) {
  const { rootFolder } = useRootFolder()
  const router = useRouter()

  const { can } = usePermissions()

  const canCreateProject = can('create', 'Project')

  const handleCreateProject = () => {
    const clientId = rootFolder?.clientId

    const createProjectUrl = qs.stringifyUrl({
      url: '/create-project',
      query: { owner: rootFolder?.owner, clientId },
    })

    router.push(createProjectUrl)
  }

  return (
    <>
      {!hideActions && (
        <div className='flex gap-x-2'>
          <FolderHeaderFilter surveysFilter={surveysFilter} />

          {canCreateProject && (
            <Button
              className='p-button p-button-primary p-button-sm'
              icon={PrimeIcons.PLUS}
              label='Create project'
              onClick={handleCreateProject}
            />
          )}
        </div>
      )}
    </>
  )
}
