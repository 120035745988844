import { useRouter } from 'next/router'
import { Column } from 'primereact/column'

import DataTable from '@components/DataTable'
import { TABLE_COLUMN_TYPE } from '@constants'
import openLinkOnCmdClick from '@helpers/open-link-on-cmd-click'
import { usePermissions } from '@hooks/use-permissions'
import { useFilteredSurveys } from '@views/surveys/hooks/use-filtered-surveys'
import { useProjectColumnStructure } from '@views/surveys/hooks/use-project-column-structure'
import { useStarredProjects } from '@views/surveys/hooks/use-starred-projects'
import type { ProjectsView } from '@views/surveys/ProjectsPageContainer'
import SurveysActions from '@views/surveys/SurveysActions'
import StarredColumn from '@views/surveys/SurveysTableComponents/StarredColumn'

import type { Surveys, SurveysSearchParams } from '@typings/Survey'

type Props = {
  name: string | React.ReactNode
  view?: ProjectsView
  searchParams?: SurveysSearchParams
}

export default function Surveys({ name, searchParams, view }: Props) {
  const { surveys, surveysError, surveysFilter } = useFilteredSurveys(searchParams, view)

  const { can } = usePermissions()
  const router = useRouter()

  const projectColumnStructure = useProjectColumnStructure()
  const starredProjects = useStarredProjects()

  const canOpenProject = can('read', 'Project')

  return (
    <DataTable
      className={canOpenProject ? 'cursor-pointer' : ''}
      columnsStart={
        <Column
          body={({ id: projectId }) => (
            <StarredColumn projectId={projectId} starredProjects={starredProjects} />
          )}
          className='w-5 text-center'
        />
      }
      columnsStructure={projectColumnStructure}
      error={surveysError}
      header={{
        title: name,
        actionElements: (
          <SurveysActions hideActions={view === 'STARRED'} surveysFilter={surveysFilter} />
        ),
      }}
      removableSort
      rowHover={canOpenProject}
      sortField='name'
      sortOrder={1}
      tableType={TABLE_COLUMN_TYPE.PROJECTS}
      value={surveys}
      onRowClick={(e) => {
        if (canOpenProject) {
          const { id: surveyId } = e.data
          openLinkOnCmdClick({ router, link: `/survey/${surveyId}`, event: e.originalEvent })
        }
      }}
    />
  )
}
