import { useEffect } from 'react'

import axios from 'axios'
import { NO_REVALIDATION } from 'config/swr/options'
import toast from 'react-hot-toast'

import { makeAvailableColumnsForStructure } from '@components/DataTable/helpers/get-available-columns-for-structure'
import { handleMissingField } from '@components/DataTable/helpers/handle-missing-field'
import { useTableColumnSettingsChoices } from '@hooks/use-survey-provider-service-api'
import { useUser } from '@hooks/use-user'

import type { UserColumnChoice, UserColumnSettingsTableType } from '@typings/Users'
import type { ColumnProps } from 'primereact/column'

type UseSettingsColumnsArgs = {
  setVisibleColumns: (columns: Array<UserColumnChoice>) => void
  visibleColumns: Array<UserColumnChoice>
  tableType: UserColumnSettingsTableType
  columnsStructure: Record<string, ColumnProps & { isHidden?: boolean }>
}

export function useSettingsColumns({
  columnsStructure,
  setVisibleColumns,
  tableType,
  visibleColumns,
}: UseSettingsColumnsArgs) {
  const { user } = useUser()
  const { columnSettingsChoices } = useTableColumnSettingsChoices(tableType, NO_REVALIDATION)
  const getAvailableColumns = makeAvailableColumnsForStructure(columnsStructure)

  useEffect(() => {
    fetchColumnSettings()
  }, [])

  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    const reorderedItems = [...visibleColumns]
    const [removed] = reorderedItems.splice(result.source.index, 1)
    reorderedItems.splice(result.destination.index, 0, removed)

    setVisibleColumns(reorderedItems)
  }

  const onColumnToggle = (column: UserColumnChoice) => {
    const orderedVisibleColumns = visibleColumns.includes(column)
      ? visibleColumns.filter((item) => item !== column)
      : [...visibleColumns, column]

    setVisibleColumns(orderedVisibleColumns)
  }

  const handleColumnSettingSave = async () => {
    try {
      await axios.post(`/api/user/${user.id}/column-settings`, {
        organisationId: user.organisationId,
        tableType,
        columnChoices: handleMissingField(visibleColumns, tableType),
        updatedBy: user.email,
      })
    } catch (error) {
      toast.error('Failed to load column settings')
    }
  }

  const fetchColumnSettings = async () => {
    try {
      const { data } = await axios.get(
        `/api/user/${user.id}/column-settings?tableType=${tableType}`,
      )

      setVisibleColumns(getAvailableColumns(data.columnChoices))
    } catch (error) {
      toast.error('Failed to load column settings')
    }
  }

  const invisibleColumns = getAvailableColumns([
    ...(columnSettingsChoices?.columnChoices ?? []),
    ...(columnSettingsChoices?.piiFlexibleColumns ?? []),
  ])?.filter((columnChoice) => !visibleColumns.includes(columnChoice))

  return {
    onDragEnd,
    invisibleColumns,
    onColumnToggle,
    handleColumnSettingSave,
  }
}
