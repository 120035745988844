import React, { cloneElement } from 'react'

import { DotsSixVertical, Eye, EyeSlash } from '@phosphor-icons/react'
import { Button } from 'primereact/button'

import { REQUIRED_FIELDS_TABLE_MAP } from '@components/DataTable/data/data'
import { cx } from '@helpers/cx'
import { trackError } from '@helpers/track-error'

import type { UserColumnChoice, UserColumnSettingsTableType } from '@typings/Users'
import type { ColumnProps } from 'primereact/column'

type Props = {
  item: UserColumnChoice
  label: ColumnProps['header']
  tableType: UserColumnSettingsTableType
  innerRef?: (element: HTMLElement) => void
  onColumnToggle: (column: UserColumnChoice) => void
}

export default function ColumnSettingsSectionItem({
  innerRef,
  item,
  label,
  onColumnToggle,
  tableType,
  ...props
}: Props) {
  if (!label) {
    trackError(`Column ${item} not found in columnsStructure`)
  }

  const canDrag = Boolean(innerRef)
  const isVisibleColumn = Boolean(innerRef)
  const isRequiredField = item === REQUIRED_FIELDS_TABLE_MAP[tableType]

  const icon = cloneElement(canDrag ? <DotsSixVertical /> : <DotsSixVertical />, {
    size: 20,
    weight: 'bold',
    className: cx('text-gray-400 ml-1', canDrag ? 'cursor-grab' : 'opacity-40 cursor-default'),
  })

  return (
    <div
      ref={innerRef}
      className={cx('flex select-none items-center gap-x-2 rounded-md py-1 transition ', {
        '!cursor-default hover:bg-gray-100': canDrag,
      })}
      {...props}
    >
      {icon}

      <span className='flex-1 cursor-default text-sm font-semibold text-gray-600'>
        {label ? <>{label}</> : item}
      </span>

      <Button
        className={cx('!w-10 !text-gray-500', {
          'cursor-default opacity-50 hover:!opacity-50': isRequiredField,
        })}
        icon={isVisibleColumn ? <Eye size={18} /> : <EyeSlash size={18} />}
        link
        severity='secondary'
        size='small'
        tooltip={isRequiredField && 'This column is essential and cannot be hidden.'}
        tooltipOptions={{ position: 'bottom' }}
        onClick={isRequiredField ? undefined : () => onColumnToggle(item)}
      />
    </div>
  )
}
