import { useEffect, useState } from 'react'

import { getStarredProjectStorage } from '@views/surveys/helpers/handle-starred-project-storage'

export function useStarredProjects() {
  const [starredProjects, setStarredProjects] = useState(getStarredProjectStorage)

  useEffect(() => {
    const handleStorageChange = () => setStarredProjects(getStarredProjectStorage())
    window.addEventListener('storage', handleStorageChange)

    return () => window.addEventListener('storage', handleStorageChange)
  }, [starredProjects])

  return starredProjects
}
