import { Fragment } from 'react'

import { Check, HourglassMedium, Plus, X } from '@phosphor-icons/react'
import { Skeleton } from 'primereact/skeleton'
import { Tooltip } from 'primereact/tooltip'

import { WORKFLOW_STATE_TYPES } from '@constants'
import WorkflowStateTypeLabel from '@views/workflows/components/WorkflowStateTypeLabel'

import type { ProjectsStatsData } from '@typings/Survey'
import type { WorkflowStateType } from '@typings/Workflows'

type Props = {
  projectStats: ProjectsStatsData
  hiddenStates?: Array<WorkflowStateType>
  itemId: string
  isLoading?: boolean
}

const { ACCEPTED, IN_PROGRESS, NEW, NOT_ACCEPTED } = WORKFLOW_STATE_TYPES

const workflowsStateTypes = [
  { icon: <Check />, id: ACCEPTED, name: 'Accepted responses' },
  { icon: <X />, id: NOT_ACCEPTED, name: 'Not accepted responses' },
  { icon: <HourglassMedium />, id: IN_PROGRESS, name: 'In progress responses' },
  { icon: <Plus />, id: NEW, name: 'New responses' },
]

const wrapperClassname = 'flex gap-2'

export default function WorkflowStateProgress({
  hiddenStates = [],
  isLoading,
  itemId,
  projectStats,
}: Props) {
  if (isLoading) {
    return (
      <div className={wrapperClassname}>
        <Skeleton borderRadius='1rem' height='1.5rem' width='2.75rem' />
        <Skeleton borderRadius='1rem' height='1.5rem' width='2.75rem' />
        <Skeleton borderRadius='1rem' height='1.5rem' width='2.75rem' />
      </div>
    )
  }

  if (!projectStats) {
    return <span>-</span>
  }

  return (
    <div className={wrapperClassname}>
      {workflowsStateTypes.map(({ icon, id, name }) => {
        const target = `project-${itemId}-${id}`.toLowerCase()

        // exclude stats that are considered hidden
        if (hiddenStates.includes(id)) {
          return null
        }

        return (
          <Fragment key={id}>
            <div
              className='flex cursor-help items-center gap-2'
              data-pr-position='bottom'
              data-pr-tooltip={name}
              data-tooltip={target}
            >
              <WorkflowStateTypeLabel
                icon={icon}
                workflowStateName={String(projectStats[id] || 0)}
                workflowStateType={id}
              />
            </div>

            <Tooltip target={`[data-tooltip=${target}]`} />
          </Fragment>
        )
      })}
    </div>
  )
}
