import { isValidElement } from 'react'

type Props = {
  children: React.ReactNode
  title: string
}

export default function ColumnSettingsSection({ children, title }: Props) {
  if (isValidElement(children) || (Array.isArray(children) && children.length > 0)) {
    return (
      <>
        <h3 className='select-none px-2 text-xs font-semibold text-gray-600'>{title}</h3>
        <div>{children}</div>
      </>
    )
  }
}
