import { useRef } from 'react'

import { Gear } from '@phosphor-icons/react'
import { Button } from 'primereact/button'
import { OverlayPanel } from 'primereact/overlaypanel'

import ColumnSettings from '@components/DataTable/ColumnSettings'
import { useSettingsColumns } from '@components/DataTable/hooks/use-settings-columns'

import type { UserColumnChoice, UserColumnSettingsTableType } from '@typings/Users'
import type { ColumnProps } from 'primereact/column'

type Props = {
  tableType: UserColumnSettingsTableType
  setVisibleColumns: (columns: Array<UserColumnChoice>) => void
  visibleColumns: Array<UserColumnChoice>
  columnsStructure: Record<string, ColumnProps & { isHidden?: boolean }>
}

export default function ColumnSettingsButton({
  columnsStructure,
  setVisibleColumns,
  tableType,
  visibleColumns,
}: Props) {
  const overlayRef = useRef(null)

  const { handleColumnSettingSave, invisibleColumns, onColumnToggle, onDragEnd } =
    useSettingsColumns({
      setVisibleColumns,
      visibleColumns,
      tableType,
      columnsStructure,
    })

  return (
    <>
      <Button
        icon={<Gear size={20} />}
        outlined
        severity='secondary'
        size='small'
        type='button'
        onClick={(e) => overlayRef.current.toggle(e)}
      />

      <OverlayPanel ref={overlayRef} className='rounded-md' onHide={handleColumnSettingSave}>
        <ColumnSettings
          columnsStructure={columnsStructure}
          invisibleColumns={invisibleColumns}
          tableType={tableType}
          visibleColumns={visibleColumns}
          onColumnToggle={onColumnToggle}
          onDragEnd={onDragEnd}
        />
      </OverlayPanel>
    </>
  )
}
